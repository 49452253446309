import React from 'react'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import get from 'lodash/get'

class Resume extends React.Component {
  render() {
    const { data } = this.props

    //TODO clean this and try/catch?
    const resumeSrc = get(data, 'resume.edges')[0].node.publicURL
    let heightForEmbeded = '0'

    if (typeof window !== 'undefined') {
      // only get the height on client. This check prevents a build failure
      // when window object is not present server side.
      heightForEmbeded = window.innerHeight
    }

    return (
      <Layout isHome={false}>
        <section
          className="container content-section text-center"
          data-cy="resume-component"
          id="about"
        >
          <div className="row">
            <h2 className="home-titles"> Résumé </h2>
            <div id="download-button">
              <a href={resumeSrc} download>
                <button className="btn btn-center btn-xl">
                  Download Résumé
                </button>
              </a>
            </div>
          </div>
          <embed
            src={resumeSrc}
            type="application/pdf"
            width="80%"
            height={heightForEmbeded ? heightForEmbeded : '0'}
          ></embed>
        </section>
      </Layout>
    )
  }
}

export default Resume

export const query = graphql`
  query resumeQuery {
    resume: allFile(filter: { publicURL: { regex: "/resume/" } }) {
      edges {
        node {
          publicURL
          extension
        }
      }
    }
  }
`
